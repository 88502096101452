import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import GalleryLayout from "../layouts/galleryLayout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  margin-top: 1rem;
  box-shadow: ${props => props.theme.imageBoxShadow};
`

const GalleryHome = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      galleryJson(category: { eq: "hero" }) {
        images(name: "lugard-road") {
          src {
            cloudinary: childCloudinaryAsset {
              fluid(
                maxWidth: 1300
                transformations: ["f_auto", "q_auto:best"]
              ) {
                ...CloudinaryAssetFluid
              }
              fixed(transformations: ["t_og_image", "f_jpg"]) {
                ...CloudinaryAssetFixed
              }
            }
          }
          alt
        }
      }
    }
  `)

  const [image] = data.galleryJson.images

  return (
    <GalleryLayout>
      <SEO
        title="Gallery"
        description="My photography gallery."
        image={image.src.cloudinary.fixed.src}
        pathname={location.pathname}
      />
      <Wrapper tabIndex="0">
        <Img fluid={image.src.cloudinary.fluid} alt={image.alt} />
      </Wrapper>
    </GalleryLayout>
  )
}

export default GalleryHome
